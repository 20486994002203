body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.donation-select .radio {
  margin-left: 1rem;
}

.donation-select .radio input{
  margin-right: 0.25rem;
}

.tag.is-outlined {
  outline: 1px solid black;
}

.donation-select .tag {
  cursor: pointer;
}

.nav-link {
  padding-left: 10px;
}
